// Core
import React, { useState } from "react";
import { Link as SlowLink } from "react-scroll";
import { useTranslation } from "react-i18next";

// Helpers
import { getDurationOfAnimation } from "../../helper";

// Styles
import "./Header.scss";

// Images
import Logo from "../../assets/img/Logo.svg";

// Components
import LanguageFlags from "../LanguageFlags";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { t } = useTranslation();

  const headerLinks = t("projects.headerLinks");

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    const rootEl = document.querySelector("#root");

    if (showMenu) {
      rootEl.classList.remove("hidden");
    } else {
      rootEl.classList.add("hidden");
    }
  };

  return (
    <header id="header" className="container-fluid header">
      <div>
        <nav className="top-header flex my-10 items-center navbar navbar-expand-md navbar-light none-padding">
          <div className="logo">
            <img draggable={false} src={Logo} alt="logo" />
          </div>
          <div className="collapse navbar-collapse" id="navbar-menu">
            <div className="navbar-nav">
              {headerLinks.map(({ title, id }) => (
                <SlowLink
                  key={id}
                  className="link_animation"
                  to={id}
                  smooth={true}
                  duration={getDurationOfAnimation()}
                >
                  {title}
                </SlowLink>
              ))}
            </div>
          </div>
          <div
            onClick={toggleMenu}
            className={`header__menu d-md-none ${showMenu ? "open" : ""}`}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <LanguageFlags color={"#005dd9"} />
        </nav>
        <div className={`header-menu js-menu ${showMenu ? "open" : ""}`}>
          <ul>
            {headerLinks.map(({ title, id }) => (
              <li key={id} className="link_animation">
                <SlowLink
                  onClick={toggleMenu}
                  key={id}
                  className="scroll js-mob-scroll"
                  to={id}
                  smooth={true}
                  duration={getDurationOfAnimation()}
                >
                  {title}
                </SlowLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
