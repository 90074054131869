import React from "react";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";

// Styles
import "./assets/styles/index.scss";

// Components
import Header from "./components/Header";
import Projects from "./components/Projects";

const App = () => {
  const { t } = useTranslation();

  return (
    <div className="main-container">
      <Header />
      <Element name="Our Projects">
        <Projects
          title={t("projects.title")}
          subTitle={t("projects.subTitle")}
        />
      </Element>

      <div className="pb-3 px-2 text-center">
        <p className="aside-text">{t("projects.footerText")}</p>
        <p>© Dnipro Innovation Box</p>
      </div>
    </div>
  );
};

export default App;
