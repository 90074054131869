const getDurationOfAnimation = () => {
  let ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("safari") !== -1) {
    if (ua.indexOf("chrome") > -1) {
      return 100;
    } else {
      return 500;
    }
  }
};

export { getDurationOfAnimation };
