import { useTranslation } from "react-i18next";

// Components
import ProjectCard from "../ProjectCard/index";

const Projects = ({ title, subTitle }) => {
  const { t } = useTranslation();

  const projects = t("projects.allProjects");

  let projectsToRender = projects.filter((project) => !!project);

  return (
    <section id="projects" className="works container-fluid">
      {title && <h5>{title}</h5>}
      {subTitle && <h2>{subTitle}</h2>}
      <div className="row">
        {projectsToRender.map((project) => {
          return <ProjectCard key={project.id} project={project} />;
        })}
      </div>
    </section>
  );
};

export default Projects;
