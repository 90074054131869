// Styles
import "./ProjectCard.scss";

const ProjectCard = ({ project }) => {
  const columns = 12 / 2;

  return (
    <div className={`col-md-${columns} project__card p-2`}>
      <a href={project.link} target="_blank" rel="noreferrer">
        <div className="image__zoom project_animation">
          <img draggable={false} src={project.image} alt={`${project.title}`} />
          <div className="pos-absolute">
            <h3>{project.title}</h3>
          </div>
        </div>
      </a>
    </div>
  );
};

export default ProjectCard;
