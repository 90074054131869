import { useState } from "react";
import cookies from "js-cookie";

// I18Next
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ReactCountryFlag from "react-country-flag";

// Components
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LanguageIcon from "@mui/icons-material/Language";

export default function LanguageFlags({ color }) {
  const languages = [
    {
      code: "en",
      country_code: "GB",
      name: "English",
    },
    {
      code: "ua",
      country_code: "UA",
      name: "Українська",
    },
  ];

  const { t } = useTranslation();

  const currentLanguageCode = cookies.get("i18next") || "ua";

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="language-flags">
      <Tooltip title={t("language")} arrow>
        <IconButton onClick={handleClick} size="small">
          <LanguageIcon style={{ width: "32px", height: "32px", color }} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        disableScrollLock={true}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            bgcolor: "#F8F8F8",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "#F8F8F8",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {languages.map(({ code, name, country_code }) => (
          <Tooltip key={code} title={name} arrow placement="left">
            <MenuItem
              style={{
                backgroundColor:
                  currentLanguageCode === code ? "#D8D8D8" : "#F8F8F8",
                border:
                  currentLanguageCode === code
                    ? "1px solid #989898"
                    : "#F8F8F8",
              }}
            >
              <IconButton
                onClick={() => {
                  i18next.changeLanguage(code);
                }}
              >
                <ReactCountryFlag
                  countryCode={country_code}
                  svg
                  style={{
                    opacity: currentLanguageCode === code ? 1 : 0.3,
                    width: "3rem",
                    height: "2rem",
                  }}
                />
              </IconButton>
            </MenuItem>
          </Tooltip>
        ))}
      </Menu>
    </div>
  );
}
